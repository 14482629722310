@use '_module-index' as *;
// COMPONENT [ HOMEPAGE ] #############################################################################################

// A.1. GLOBAL STYLE --------------------------------------

.oPageHome {
    padding: 63px 0 0;
}

.oAboutSection { margin: 140px auto;
    .mCtaWrapper { margin: 0 16px; display: block; max-width: 1128px; width: 100%; }
}


// A.1. END -----------------------------------------------

// B. MEDIA QUERIES ---------------------------------------

// B.1. GRACEFUL DEGRADATION ---------------

@media ($md-dn) {
}

@media ($xs-dn) {
}

// B.1. END --------------------------------

// B.2. PROGRESSIVE ENHANCEMENT ------------
// 576px up
@media ($sm-up) {
}

// 768px up
@media ($md-up) {
    .oAboutSection {
        .mCtaWrapper { margin: 0 auto; padding: 0 16px; }
    }
}

// 992px up
@media ($lg-up) {
    .oPageHome {
        padding: 80px 0 0;
    }
}

// 1200px up
@media ($xl-up) {
    .oAboutSection {
        .mCtaWrapper { padding: 0; }
    }
}

// 1400px up
@media ($xxl-up) {
}

// B.2. END --------------------------------

// B. END -------------------------------------------------

// END OF FILE ########################################################################################################
